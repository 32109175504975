import { Routes } from '@angular/router';
import { MfAccountPageComponent } from '@app/account/page/page.component';
import { MfLayoutPageComponent } from '@app/layout/page/page.component';
import { MfSelectionTypeEnum } from '@app/selection/data/type/type.enum';
import { MfAuthenticationGuard } from '@shared/authentication/guard/authentication.guard';
import { MfOnlyAnonymusGuard } from '@shared/authentication/guard/only-anonymus-guard.service';
import { MfUserRolesEnum } from '@shared/authentication/types/user_roles.enum';
import { BankFeatures } from '@shared/bank-customization/bank-customization.type';
import { MfBankCustomizationFeatureGuard } from '@shared/bank-customization/guard/bank-feature.guard';
import { MfDashboardRedirectComponent } from '@shared/dashboard-redirect/dashboard-redirect.component';
import { MfDevOnlyRouteGuard } from '@shared/guards/dev-only-route/dev-only-route.guard';
import { MfAppArea } from '@shared/navigation/navigation.types';

export default [
  {
    path: 'marktplatz',
    loadChildren: () => import('./marketplace/marketplace.routes'),
    canLoad: [MfAuthenticationGuard],
    data: {
      roles: [MfUserRolesEnum.MARKETPLACE_BUYER, MfUserRolesEnum.MARKETPLACE_SELLER],
    },
  },
  {
    path: '',
    component: MfLayoutPageComponent,
    data: {
      area: MfAppArea.MARKETINGFABRIK,
    },
    children: [
      {
        path: '',
        component: MfAccountPageComponent,
        children: [
          {
            path: '',
            redirectTo: 'benutzerkonto/einloggen',
            pathMatch: 'full',
          },
          {
            path: 'benutzerkonto/passwort-vergessen',
            loadComponent: () =>
              import('./account/password-forgotten/password-forgotten.component').then(
                (i) => i.MfAccountPasswordForgottenComponent
              ),
            data: {
              pageTitle: 'SIDENAV.NAVIGATION.ACCOUNT.PASSWORD_FORGOTTEN.TITLE',
            },
          },
          {
            path: 'benutzerkonto/einloggen',
            loadComponent: () =>
              import('./account/login/login.component').then((i) => i.MfAccountLoginComponent),
            canActivate: [MfOnlyAnonymusGuard],
            data: {
              pageTitle: 'SIDENAV.NAVIGATION.ACCOUNT.LOGIN.TITLE',
            },
          },
          {
            path: 'benutzerkonto/passwort-neu-vergeben/:token',
            loadComponent: () =>
              import('./account/password-reenter/password-reenter.component').then(
                (i) => i.MfAccountPasswordReenterComponent
              ),
            data: {
              pageTitle: 'SIDENAV.NAVIGATION.ACCOUNT.PASSWORD_REENTER.TITLE',
            },
          },
          {
            path: 'benutzerkonto/aktivieren/:token',
            loadComponent: () =>
              import('./account/activation/activation.component').then(
                (c) => c.MfAccountActivationComponent
              ),
            data: {
              pageTitle: 'SIDENAV.NAVIGATION.ACCOUNT.ACTIVATION.TITLE',
            },
          },
          {
            path: 'benutzerkonto/email-bestaetigen/:token',
            loadComponent: () =>
              import('./account/email-confirmation/email-confirmation.component').then(
                (c) => c.MfAccountEmailConfirmationComponent
              ),
            data: {
              pageTitle: 'SIDENAV.NAVIGATION.ACCOUNT.EMAIL_CONFIRMATION.TITLE',
            },
          },
          {
            path: 'zustimmung-report/email-abmelden/:token',
            data: {
              pageTitle: 'SIDENAV.NAVIGATION.CONSENT_ACTION.REPORT.UNSUBSCRIBE_EMAIL',
            },
            loadComponent: () =>
              import('./consent-action/report/email-unsubscribe/email-unsubscribe.component').then(
                (m) => m.MfConsentActionReportEmailUnsubscribeComponent
              ),
          },
        ],
      },
      {
        path: 'kampagnen',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER, MfUserRolesEnum.PARTNER_MARKETING_MANAGER],
        },
        loadChildren: () => import('./campaign-manager/campaign-manager.routes'),
      },
      {
        path: 'medienuebersicht',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER],
        },
        loadChildren: () => import('./visual/visual.routes'),
      },
      {
        path: 'kampagnenseiten',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER],
        },
        loadChildren: () => import('./campaign-landingpage/campaign-landingpage.routes'),
      },
      {
        path: 'newsletter',
        canLoad: [MfAuthenticationGuard, MfBankCustomizationFeatureGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER],
          features: {
            bank: BankFeatures.NEWSLETTER,
          },
        },
        loadChildren: () => import('./newsletter/newsletter.routes'),
      },
      {
        path: 'selektionen',
        canLoad: [MfAuthenticationGuard],
        canActivateChild: [MfAuthenticationGuard],
        data: {
          roles: [
            MfUserRolesEnum.CAMPAIGN_MANAGER,
            MfUserRolesEnum.PARTNER_MARKETING_MANAGER,
            MfUserRolesEnum.DATA_MANAGER,
            MfUserRolesEnum.PARTNER_DATA_MANAGER,
          ],
          selectionDataType: MfSelectionTypeEnum.TYPE_INCLUSION,
        },
        loadChildren: () => import('./selection/selection.routes'),
      },
      {
        path: 'ausschluesse',
        canLoad: [MfAuthenticationGuard],
        canActivateChild: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.DATA_MANAGER, MfUserRolesEnum.PARTNER_DATA_MANAGER],
          selectionDataType: MfSelectionTypeEnum.TYPE_EXCLUSION,
        },
        loadChildren: () => import('./selection/selection.routes'),
      },
      {
        path: 'organisation',
        canLoad: [MfAuthenticationGuard],
        canActivateChild: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER, MfUserRolesEnum.DATA_MANAGER],
        },
        loadChildren: () => import('./organization/organization.routes'),
      },
      {
        path: 'briefverwaltung',
        canLoad: [MfAuthenticationGuard],
        canActivateChild: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER, MfUserRolesEnum.PARTNER_MARKETING_MANAGER],
        },
        loadChildren: () => import('./letter-management/letter-management.routes'),
      },
      {
        path: 'marktfolge',
        canLoad: [MfAuthenticationGuard],
        canActivateChild: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.ADMIN_BACK_OFFICE, MfUserRolesEnum.BACK_OFFICE],
        },
        loadChildren: () => import('./document-form-scan/document-form-scan.routes'),
      },
      {
        path: 'anleitungsvideos',
        canLoad: [MfAuthenticationGuard],
        canActivateChild: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.ADMIN_VIDEO_MANAGEMENT],
        },
        loadChildren: () => import('./instruction-video/instruction-video.routes'),
      },
      {
        path: 'abrechnungen',
        canLoad: [MfAuthenticationGuard],
        canActivateChild: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER, MfUserRolesEnum.PARTNER_MARKETING_MANAGER],
        },
        loadChildren: () => import('./billing/billing.routes'),
      },
      {
        path: 'datentransfer',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.DATA_MANAGER, MfUserRolesEnum.PARTNER_DATA_MANAGER],
        },
        loadChildren: () => import('./data-manager/data-manager.routes'),
      },
      {
        path: 'bank-administration',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.BANK_ADMIN, MfUserRolesEnum.PARTNER_ADMIN],
        },
        loadChildren: () => import('./bank-admin/bank-admin.routes'),
      },
      {
        path: 'emni-administration',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [
            MfUserRolesEnum.ADMIN_BANK_MANAGEMENT,
            MfUserRolesEnum.ADMIN_EMAIL_TEMPLATES,
            MfUserRolesEnum.ADMIN_USER_MANAGEMENT,
            MfUserRolesEnum.ADMIN_DOCUMENT_MANAGEMENT,
            MfUserRolesEnum.ADMIN_MAILJET,
            MfUserRolesEnum.ADMIN_IDA,
            MfUserRolesEnum.ADMIN_MEDIA_LIBRARY,
          ],
        },
        loadChildren: () => import('./emni-admin/emni-admin.routes'),
      },
      {
        path: 'partner/administration',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.PARTNER_ADMIN],
        },
        loadChildren: () => import('./partner/admin/partner-admin.routes'),
      },
      {
        path: 'partner/adressdaten',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.PARTNER_MARKETING_MANAGER, MfUserRolesEnum.PARTNER_BANK_EMPLOYEE],
        },
        loadChildren: () => import('./partner/recipient-list/partner-recipient-list.routes'),
      },
      {
        path: 'versandorganisation',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.DATA_MANAGER, MfUserRolesEnum.PARTNER_DATA_MANAGER],
        },
        loadChildren: () => import('./shipping-organization/shipping-organization.routes'),
      },
      {
        path: 'dashboard',
        component: MfDashboardRedirectComponent,
        canActivate: [MfAuthenticationGuard],
      },
      {
        path: 'revision',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.REVISION],
        },
        loadChildren: () => import('./revision/revision.routes'),
      },
      {
        path: 'finanzhaus',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER],
        },
        loadChildren: () => import('./finance-house/finance-house.routes'),
      },
      {
        path: 'hilfe-informationen',
        canLoad: [MfAuthenticationGuard],
        loadChildren: () => import('./support-and-information/support-and-information.routes'),
      },
      {
        path: 'prueflisten',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.DATA_MANAGER, MfUserRolesEnum.PARTNER_DATA_MANAGER],
        },
        loadChildren: () => import('./selection-checklist/selection-checklist.routes'),
      },
      {
        path: 'zustimmungsaktionen',
        canLoad: [MfAuthenticationGuard, MfBankCustomizationFeatureGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER],
          features: {
            bank: BankFeatures.CONSENT_ACTION,
          },
        },
        loadChildren: () => import('./consent-action/consent-action.routes'),
      },
      {
        path: 'zustimmungsaktionen-gruppen',
        canLoad: [MfAuthenticationGuard, MfBankCustomizationFeatureGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER],
          features: {
            bank: BankFeatures.CONSENT_ACTION,
          },
        },
        loadChildren: () => import('./consent-action-group/consent-action-group.routes'),
      },
      {
        path: 'zustimmungsseiten',
        canLoad: [MfAuthenticationGuard, MfBankCustomizationFeatureGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER],
          features: {
            bank: BankFeatures.CONSENT_ACTION,
          },
        },
        loadChildren: () =>
          import('./consent-action-landing-page/consent-action-landing-page.routes'),
      },
      {
        path: 'dokumentenarchiv',
        canLoad: [MfAuthenticationGuard, MfBankCustomizationFeatureGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER],
          features: {
            bank: BankFeatures.CONSENT_ACTION,
          },
          pageTitle: 'SIDENAV.NAVIGATION.DOCUMENT_ARCHIVE',
        },
        loadChildren: () => import('./document-archive/document-archive.routes'),
      },
      {
        path: 'serviceprozesse',
        canLoad: [MfAuthenticationGuard, MfBankCustomizationFeatureGuard],
        data: {
          roles: [MfUserRolesEnum.CAMPAIGN_MANAGER],
          features: {
            bank: [
              BankFeatures.EMAIL_APPOINTMENT_REMINDER,
              BankFeatures.CONSENT_ACTION_COLLECT_MISSING_EMAILS,
              BankFeatures.CONSENT_ACTION_COLLECT_MISSING_EPOST,
            ],
          },
        },
        loadChildren: () => import('./service-process/service-process.routes'),
      },
      {
        path: 'zielgruppen',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.DATA_MANAGER],
          pageTitle: 'TARGET_GROUP.TITLE',
        },
        loadChildren: () => import('./target-group/target-group.routes'),
      },
      {
        path: 'produkte',
        canLoad: [MfAuthenticationGuard, MfBankCustomizationFeatureGuard],
        data: {
          roles: [MfUserRolesEnum.BANK_ADMIN],
          features: {
            bank: [BankFeatures.PRODUCTS],
          },
        },
        loadChildren: () => import('./product/product.routes'),
      },
      {
        path: 'benutzerverwaltung',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [
            MfUserRolesEnum.PARTNER_ADMIN,
            MfUserRolesEnum.ADMIN_USER_MANAGEMENT,
            MfUserRolesEnum.BANK_ADMIN,
          ],
        },
        loadChildren: () => import('./user/user.routes'),
      },
      {
        path: 'vertragsvorlagen',
        canLoad: [MfAuthenticationGuard],
        data: {
          roles: [MfUserRolesEnum.ADMIN_CONTRACT_MANAGEMENT],
        },
        loadChildren: () => import('./contract-template/contract-template.routes'),
      },
      {
        path: 'vertraege',
        loadChildren: () => import('./contract/contract.routes'),
      },
      {
        path: 'promotionen',
        loadChildren: () => import('./promotion/promotion.routes'),
      },
      {
        path: 'dev',
        canLoad: [MfDevOnlyRouteGuard],
        loadChildren: () => import('./dev/dev.routes'),
      },
      {
        path: 'werkzeugkasten',
        canLoad: [MfDevOnlyRouteGuard],
        loadChildren: () => import('@app/toolbox/toolbox.routes'),
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'benutzerkonto/einloggen',
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'benutzerkonto/einloggen',
  },
] satisfies Routes;
