import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MfBankWithEnabledFeatures,
  MfDataBankInterface,
  MfDataCreateBankInterface,
  MfDataUpdateBankInterface,
  MfDataUpdateBankPasswordInterface,
  MfDataUpdateBankPseudonymizationPasswordInterface,
} from '@app/shared/data/data.interface';
import { DateUtil } from '@app/shared/util/date/date.util';
import { DeepPartial } from '@shared/types/util.types';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MfBankDataService {
  constructor(private http: HttpClient) {}

  public getUrlStatus(url: string): Observable<void[]> {
    const encodedUrl: string = encodeURIComponent(url);

    return this.http.get<void[]>(`v1/urlstatus?url=${encodedUrl}`);
  }

  // bank related API calls

  public getBanks(): Observable<MfDataBankInterface[]> {
    return this.http.get<MfDataBankInterface[]>('v1/banks');
  }

  public getBanksWithEnabledFeaturesValue() {
    return this.http.get<MfBankWithEnabledFeatures[]>('v1/banks?withFeatures=true');
  }

  public getBank(id: number): Observable<MfDataBankInterface> {
    return this.http
      .get<MfDataBankInterface<string>>(`v1/banks/${id}`)
      .pipe(map((result) => this.parseBank(result)));
  }

  public getActivatedEmailDomains(uuid?: string): Observable<string[]> {
    if (!uuid) {
      return of([]);
    }

    return this.http.get<string[]>(`v1/banks/${uuid}/email-domains`);
  }

  public createBank(formModel: MfDataCreateBankInterface): Observable<MfDataBankInterface> {
    return this.http
      .post<MfDataBankInterface<string>>('v1/banks', formModel)
      .pipe(map((result) => this.parseBank(result)));
  }

  public updateBank(
    bankId: number,
    formModel: DeepPartial<MfDataUpdateBankInterface>
  ): Observable<MfDataBankInterface> {
    return this.http
      .patch<MfDataBankInterface<string>>(`v1/banks/${bankId}`, formModel)
      .pipe(map((result) => this.parseBank(result)));
  }

  public updateBankPseudonymizationPassword(
    formModel: MfDataUpdateBankPseudonymizationPasswordInterface
  ): Observable<Record<any, any>> {
    return this.http.patch<any>('v1/bank/change-password', formModel);
  }

  public updateMyBankPassword(
    formModel: MfDataUpdateBankPasswordInterface
  ): Observable<Record<any, any>> {
    return this.http.patch<any>('v1/bank/password', formModel);
  }

  public parseBank(bank: MfDataBankInterface<string | Date>): MfDataBankInterface {
    return {
      ...bank,
      createdAt: DateUtil.safeParseDate(bank.createdAt)!,
      updatedAt: DateUtil.safeParseDate(bank.updatedAt)!,
    };
  }
}
