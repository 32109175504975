import { ArrayUtil } from '@shared/util/array.util';

export const toQueryParamsString = <V = string | null | undefined>(
  parameters: Record<string, V>,
  startWithQuestionMark: boolean = true,
  path: string = ''
): string => {
  const paramsString = Object.entries(parameters)
    .filter(([_key, value]) => value !== null && value !== undefined)
    .map(([key, value]) => {
      if (value instanceof Date) {
        return key + '=' + value.toISOString();
      }

      if (Array.isArray(value)) {
        if (!value.length) {
          return undefined;
        }

        return value
          .map((item, index) => {
            if (typeof item === 'object') {
              return toQueryParamsString(
                item,
                false,
                getPathWithKey(path, key) + '[' + index + ']'
              );
            } else {
              return toQueryParamsString({ [key + '[' + index + ']']: item }, false, path);
            }
          })
          .join('&');
      }

      if (typeof value === 'object') {
        return toQueryParamsString(value as Record<string, V>, false, getPathWithKey(path, key));
      }

      return getPathWithKey(path, key) + '=' + encodeURIComponent(value + '');
    })
    .filter(ArrayUtil.truthyFilter)
    .join('&');

  return paramsString ? (startWithQuestionMark ? '?' : '') + paramsString : '';
};

const getPathWithKey = (path: string, key: string) => !!path ? path + '[' + key + ']' : key;
