import { EnvironmentTypeEnum } from '@env/environment-type.enum';
import { EnvironmentInterface } from './environment.interface';

export const environment: EnvironmentInterface = {
  type: EnvironmentTypeEnum.QS,
  production: true,
  hmr: false,
  serverUrl: 'https://qs-backend.marketingfabrik.app/',
  baseUrl: '//qs-frontend.marketingfabrik.app',
  landingPageUrl: 'https://qs-landing-page.marketingfabrik.app',
  oauthClientId: '1_59otuq0ph0ws08skk0s04kogcgs8skosg8s04gso8808k8oscg',
  oauthClientSecret: '2n778sku2xus4wcs4ws0wkggw0ck00osk080cogkcwg00ww4k4',
  sentry: {
    dsn: 'https://cf7162dd2e044fac8567acc65d48a077@sentry.indiwe.de/2',
    showDialog: false,
    sampleRate: 1,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  },
};
