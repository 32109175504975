// copied from backends App\Exception\HttpExceptionError class
export enum MfHttpErrorResponseCode {
  UNKNOWN = 0,
  NOT_FOUND = 1001,
  PSEUDO_PASSWORD_WRONG = 1004,
  DELIMITER_NOT_SUPPORTED = 1005,
  LINK_EXPIRED = 1009,
  // App/Entity/GenericFile related errors
  GENERIC_FILE_TOO_LARGE = 9001,
  GENERIC_FILE_IMPORT_TIMEOUT = 9002,
  GENERIC_FILE_MIME_TYPE_NOT_SUPPORTED = 9003,
  GENERIC_FILE_IMAGE_TOO_NARROW = 9004,
  GENERIC_FILE_IMAGE_TOO_WIDE = 9005,
  GENERIC_FILE_IMAGE_TOO_SHORT = 9006,
  GENERIC_FILE_IMAGE_TOO_TALL = 9007,
  GENERIC_FILE_ASPECT_RATIO_TOO_SMALL = 9008,
  GENERIC_FILE_ASPECT_RATIO_TOO_LARGE = 9009,
  BANK_COULD_NOT_BE_CREATED = 10001,
  BANK_COULD_NOT_BE_UPDATED = 10002,
  BANK_COULD_NOT_BE_CREATED_DUPLICATE_BIC = 10003,
  BANK_PSEUDO_PASSWORD_WRONG = 10004,
  USER_COULD_NOT_BE_CREATED = 10011,
  USER_PASSWORD_REENTER_FAILED = 10012,
  USER_PASSWORD_REQUEST_FAILED = 10013,
  USER_EDIT_FAILED = 10014,
  USER_DISABLE_FAILED = 10015,
  USER_IS_DISABLED = 10016,
  USER_UNBANNED_FAILED = 10017,
  USER_IS_BANNED = 10018,
  USER_EMAIL_CONFIRMATION_FAILED = 10021,
  CERTIFICATE_COULD_NOT_BE_IMPORTED = 10022,
  USER_INVALID_ROLES_COMBINATION = 10023,
  USER_EMAIL_ADDRESS_NOT_AVAILABLE = 10024,
  CAMPAIGN_COULD_NOT_BE_CREATED = 10030,
  CAMPAIGN_COULD_NOT_BE_UPDATED = 10031,
  CAMPAIGN_MEDIUM_COULD_NOT_BE_CREATED = 10032,
  CAMPAIGN_MEDIUM_COULD_NOT_BE_UPDATED = 10032,
  CAMPAIGN_MEDIUM_NOT_DOWNLOADED = 10033,
  CAMPAIGN_MEDIUM_DOWNLOAD_PARAMETERS_WRONG = 10034,
  CAMPAIGN_MEDIUM_DOWNLOAD_PASSWORD_WRONG = 10035,
  CAMPAIGN_MEDIUM_NOT_DOWNLOADABLE = 10036,
  CAMPAIGN_MEDIUM_DOWNLOAD_FAILED = 10037,
  CAMPAIGN_PAUSE_MESSAGE_MISSING = 10038,
  SELECTION_COULD_NOT_BE_CREATED = 10040,
  SELECTION_COULD_NOT_BE_UPDATED = 10041,
  SELECTION_VERSION_COULD_NOT_BE_CREATED = 10042,
  SELECTION_VERSION_COULD_NOT_BE_READ = 10043,
  SELECTION_VERSION_COULD_NOT_BE_UPDATED = 10044,
  SELECTION_COULD_NOT_BE_ARCHIVED = 10045,
  EMAIL_TEMPLATE_COULD_NOT_BE_CREATED = 10050,
  EMAIL_TEMPLATE_COULD_NOT_BE_UPDATED = 10051,
  EMAIL_TEMPLATE_DISABLE_FAILED = 10052,
  EMAIL_TEMPLATE_COULD_NOT_SEND_TESTEMAIL = 10053,
  TEST_EMAIL_PLACEHOLDERS_MISSING = 10054,
  EMAIL_TEMPLATE_CONTAINS_SCRIPT_CODE = 10055,
  TEST_EMAIL_CUSTOM_SENDER_FAILED = 10056,
  EMAIL_TEMPLATE_WITH_UNIQUE_USAGE_TYPE_ALREADY_EXISTS_FOR_BANK = 10057,
  EMAIL_TEMPLATE_SWITCH_TO_UNIQUE_USAGE_TYPE_NOT_ALLOWED = 10058,
  EMAIL_TEMPLATE_DISABLE_NO_ACTIVE_TEMPLATES = 10059,
  BRANCH_OFFICE_COULD_NOT_BE_CREATED = 10061,
  BRANCH_OFFICE_COULD_NOT_BE_UPDATED = 10062,
  BRANCH_OFFICE_COULD_NOT_BE_DELETED = 10063,
  EMAIL_TEMPLATE_INVALID_TEMPLATE_USAGE_TYPE = 10068,
  SUPPORT_DOCUMENT_COULD_NOT_BE_CREATED = 10071,
  SUPPORT_DOCUMENT_COULD_NOT_BE_UPDATED = 10072,
  SUPPORT_DOCUMENT_COULD_NOT_BE_DELETED = 10073,
  CONSENT_ACTION_COULD_NOT_BE_SAVED = 10080,
  LETTER_TEMPLATE_COULD_NOT_BE_CREATED = 10090,
  LETTER_TEMPLATE_COULD_NOT_BE_UPDATED = 10091,
  LETTER_TEMPLATE_DISABLE_FAILED = 10092,
  TEST_EMAIL_COULD_NOT_BE_SENT = 10100,
  BILLING_USER_HAS_NO_BANK = 10110,
  MAIL_STATISTICS_NOT_FOUND = 10120,
  DOCUMENT_ARCHIVE_DOWNLOAD_FAILED = 10130,
  DOCUMENT_ARCHIVE_CONFIRM_PASSWORD_WRONG = 10131,
  DOCUMENT_ARCHIVE_COULD_NOT_BE_CREATED = 10132,
  DOCUMENT_ARCHIVE_MISSING_GENO_CONFIG = 10133,
  PARTNER_USER_ACTIVATION_FAILED = 10201,
  PARTNER_USER_DISABLE_FAILED = 10202,
  PARTNER_USER_EMAIL_NOT_FOUND_OR_CAN_NOT_BE_ADDED = 10203,
  PARTNER_USER_RELATION_EXISTS = 10204,
  PARTNER_BANK_BIC_NOT_FOUND_OR_CAN_NOT_BE_ADDED = 10301,
  PARTNER_BANK_RELATION_EXISTS = 10302,
  PARTNER_BANK_STATE_CHANGE_FAILED = 10303,
  TARGET_GROUP_DUPLICATE_HEADER_FIELDS = 10401,
  TARGET_GROUP_DUPLICATE_NAME = 10402,
  TARGET_GROUP_COULD_NOT_BE_ARCHIVED = 10403,
  TARGET_GROUP_MISSING_SERVICE_PROVIDER = 10404,
  TARGET_GROUP_MISSING_API_KEY = 10405,
  CAMPAIGN_MEDIUM_ATTACHMENTS_INVALID_ORIENTATION = 10601,
  CAMPAIGN_MEDIUM_ATTACHMENTS_INVALID_DOCUMENT = 10602,
  CONSULTANT_COULD_NOT_BE_UPDATED = 10801,
  CONSULTANT_COULD_NOT_BE_DISABLED = 10802,
  INSTRUCTION_VIDEO_COULD_NOT_BE_CREATED = 12001,
  INSTRUCTION_VIDEO_COULD_NOT_BE_UPDATED = 12002,
  INSTRUCTION_VIDEO_REMOVE_FAILED = 12003,
  MARKETPLACE_PRODUCT_COULD_NOT_BE_CREATED = 12101,
  MARKETPLACE_PRODUCT_COULD_NOT_BE_UPDATED = 12102,
  MARKETPLACE_PRODUCT_REMOVE_FAILED = 12103,
  MARKETPLACE_PRODUCT_PUBLISH_FAILED_LANDINGPAGE_NOT_AVAILABLE = 12104,
  MARKETPLACE_CART_ITEM_COULD_NOT_BE_CREATED = 12201,
  MARKETPLACE_CART_COULD_NOT_BE_UPDATED = 12202,
  MARKETPLACE_CART_ITEM_REMOVE_FAILED = 12203,
  MARKETPLACE_CART_ITEM_ALREADY_IN_CART = 12204,
  MARKETPLACE_CART_ITEM_ALREADY_BOUGHT = 12205,
  MARKETPLACE_ORDER_COULD_NOT_BE_CREATED = 12301,
  MARKETPLACE_ORDER_NO_ITEMS_IN_CART = 12302,
  MARKETPLACE_ORDER_PRODUCT_IS_DEACTIVATED = 12303,
  MARKETPLACE_SUBSCRIPTION_COULD_NOT_BE_CREATED = 12310,
  MARKETPLACE_SUBSCRIPTION_COULD_NOT_BE_UPDATED = 12311,
  MARKETPLACE_SUBSCRIPTION_ALREADY_EXISTS = 12312,
  CAMPAIGN_MEDIUM_EBANKING_UNKNOWN_IMAGE_TYPE = 20001,
  CAMPAIGN_MEDIUM_EBANKING_BASE_URL_MISSING = 20002,
  CAMPAIGN_MEDIUM_EBANKING_FILE_NOT_FOUND = 20003,
  CAMPAIGN_MEDIUM_EBANKING_IMAGE_FORMAT_WRONG = 20004,
  CAMPAIGN_MEDIUM_EBANKING_IMAGE_SIZE_TO_LARGE = 20005,
  CAMPAIGN_MEDIUM_EBANKING_IMAGE_TIMEOUT = 20006,
  CUSTOMIZED_DOCUMENT_INVALID_DOCUMENT = 20100,
  CONTRACT_NOT_SIGNABLE = 21001,
  CONTRACT_ALREADY_SIGNED = 21002,
  DATA_COULD_NOT_BE_CREATED = 30001,
  DATA_COULD_NOT_BE_UPDATED = 30002,
  DATA_COULD_NOT_BE_UNCOMPRESSED = 30003,
  DATA_COULD_NOT_BE_STORED = 30004,
  DATA_COULD_NOT_BE_EXPORTED = 30005,
  DATA_COULD_NOT_BE_UNCOMPRESSED_PASSWORD_WRONG = 30006,
  DATA_COULD_NOT_BE_STORED_PASSWORD_WRONG = 30007,
  DATA_UPLOAD_NOT_FOUND = 30008,
  DATA_FILE_FORMAT_WRONG = 30009,
  DATA_FILE_VALIDATION_FAILED = 30010,
  DATA_COULD_NOT_BE_DOWNLOADED = 30011,
  CAMPAIGN_MEDIUM_INVALID_SIGNATURE = 30030,
  DOCUMENT_PDF_COULD_NOT_BE_GENERATED = 30101,
  FEATURE_NOT_ENABLED = 40000,
  FEATURE_GDRP_RECITALS_MISSING = 40001,
  FEATURE_EMAIL_APPOINTMENT_REMINDER_TEMPLATE_MISSING = 40002,
  NEWSLETTER_MAILING_CONTACT_COULD_NOT_BE_CREATED = 40030,
  NEWSLETTER_MAILING_CONTACT_COULD_NOT_BE_UPDATED = 40031,
  NEWSLETTER_MAILING_CONTACT_REMOVE_FAILED = 40032,
  NEWSLETTER_MAILING_COULD_NOT_BE_CREATED = 40040,
  NEWSLETTER_MAILING_COULD_NOT_BE_UPDATED = 40041,
  NEWSLETTER_MAILING_REMOVE_FAILED = 40042,
  NEWSLETTER_MAILING_REMOVE_FAILED_BECAUSE_OF_NEWSLETTER_RELATION = 40043,
  NEWSLETTER_MAILING_IMPORT_COULD_NOT_BE_CREATED = 40050,
  NEWSLETTER_MAILING_IMPORT_COULD_NOT_BE_UPDATED = 40051,
  NEWSLETTER_MAILING_IMPORT_REMOVE_FAILED = 40052,
  NEWSLETTER_COULD_NOT_BE_CREATED = 40060,
  NEWSLETTER_COULD_NOT_BE_UPDATED = 40061,
  NEWSLETTER_REMOVE_FAILED = 40062,
}
