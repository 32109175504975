import { DestroyRef, inject, Injectable, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MfDataManagerExportDownloaderStatusEnum } from '@app/data-manager/components/export/downloader/status.enum';
import {
  MfDataManagerExportDataItemContentInterface,
  MfDataManagerExportDataItemInterface,
} from '@app/data-manager/services/export-data/data.interface';
import { MfDataManagerExportDataService } from '@app/data-manager/services/export-data/data.service';
import { MfFormErrorScrollUtilService } from '@app/form/shared/error-scroll/error-scroll-util.service';
import { ResponseUtil } from '@shared/util/http/response.util';
import { format } from 'date-fns';
import { saveAs } from 'file-saver-es';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MfDataManagerExportDownloaderService {
  public status$: BehaviorSubject<MfDataManagerExportDownloaderStatusEnum> =
    new BehaviorSubject<MfDataManagerExportDownloaderStatusEnum>(
      MfDataManagerExportDownloaderStatusEnum.INITIALIZE
    );
  public icon?: string;
  public iconAnimated?: boolean;
  public iconClass?: string;
  public form!: UntypedFormGroup;
  public lastRefresh: Date | null = null;
  public exportLines: number = 0;
  private unsubscribe$ = new Subject<void>();
  private serviceSubscribers: number = 0;

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private ngZone: NgZone,
    private exportDataService: MfDataManagerExportDataService,
    private mfFormErrorScrollUtilService: MfFormErrorScrollUtilService
  ) {
    this.createForm();
    this.setStatus(MfDataManagerExportDownloaderStatusEnum.INITIALIZE);
    this.initLastRefresh();
    this.initLongPolling();
  }

  onInit(): void {
    if (this.serviceSubscribers === 0) {
      this.unsubscribe$ = new Subject<void>();
    }

    this.serviceSubscribers++;
    this.checkForContent();
  }

  onDestroy(): void {
    this.serviceSubscribers--;

    if (this.serviceSubscribers === 0) {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
  }

  get status(): MfDataManagerExportDownloaderStatusEnum {
    return this.status$.getValue();
  }

  get statusAsObservable(): Observable<MfDataManagerExportDownloaderStatusEnum> {
    return this.status$.asObservable();
  }

  createForm(): void {
    this.form = this.fb.group({
      pseudonymizationPassword: ['', [Validators.required]],
    });
  }

  protected checkForContent(): void {
    const ignoreStatus: MfDataManagerExportDownloaderStatusEnum[] = [
      MfDataManagerExportDownloaderStatusEnum.DECRYPT,
      MfDataManagerExportDownloaderStatusEnum.DOWNLOAD_ERROR,
    ];

    if (!ignoreStatus.includes(this.status)) {
      this.checkForContentRequest().subscribe();
    }
  }

  private initLongPolling(): void {
    // run outside Angular to avoid script timeouts in E2E-Tests
    this.ngZone.runOutsideAngular(() => {
      interval(15000)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          filter(() => this.status === MfDataManagerExportDownloaderStatusEnum.GENERATE),
          switchMap(() => this.checkForContentRequest())
        )
        .subscribe();
    });
  }

  private checkForContentRequest(): Observable<void> {
    return this.exportDataService.getContent().pipe(
      takeUntilDestroyed(this.destroyRef),
      map((result: MfDataManagerExportDataItemContentInterface) => {
        this.exportLines = result.exportLines;

        if (result.generating) {
          this.setStatus(MfDataManagerExportDownloaderStatusEnum.GENERATE);
        } else if (result.downloadAvailable) {
          this.setStatus(MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_DOWNLOAD_ACTION);
        } else if (result.exportLines === 0) {
          this.setStatus(MfDataManagerExportDownloaderStatusEnum.EMPTY);
        } else {
          this.setStatus(MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_ACTION);
        }
      })
    );
  }

  cancel(): void {
    this.setStatus(MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_ACTION);
  }

  onSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.mfFormErrorScrollUtilService.scrollToError();

      return;
    }

    const pseudonymizationPassword = this.form.get('pseudonymizationPassword')?.value;
    this.setStatus(MfDataManagerExportDownloaderStatusEnum.DECRYPT);
    this.form.disable();

    this.exportDataService.exportFile(pseudonymizationPassword).subscribe({
      next: () => {
        this.setStatus(MfDataManagerExportDownloaderStatusEnum.GENERATE);
      },
      error: () => {
        this.setStatus(MfDataManagerExportDownloaderStatusEnum.ERROR);
      },
      complete: () => {
        this.form.enable();
      },
    });
  }

  regenerate(): void {
    this.setStatus(MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_ACTION);
  }

  downloadFile(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.mfFormErrorScrollUtilService.scrollToError();

      return;
    }

    this.setStatus(MfDataManagerExportDownloaderStatusEnum.COMPRESS);
    this.form.disable();

    this.exportDataService.downloadExportFile().subscribe({
      next: (response) => {
        if (!response.body) {
          throw Error('Recieved response with empty body!');
        }

        let filename: string = ResponseUtil.getFilenameFromContentDisposition(
          response.headers.get('content-disposition')
        );

        if (!filename) {
          const filenameDateTime: string = format(new Date(), 'yyyyMMdd_HHmmss');
          filename = filenameDateTime + '_DatenAusMarketingfabrik.zip';
        }

        saveAs(response.body, filename);

        this.exportDataService.updateExportItems();
        this.setStatus(MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_ACTION);
      },
      error: () => {
        this.setStatus(MfDataManagerExportDownloaderStatusEnum.DOWNLOAD_ERROR);
      },
      complete: () => {
        this.form.enable();
      },
    });
  }

  private initLastRefresh(): void {
    this.lastRefresh = null;

    this.exportDataService.exportItems$.subscribe(
      (exportItems: MfDataManagerExportDataItemInterface[]) => {
        if (exportItems.length) {
          this.lastRefresh = exportItems[0].createdAt;
        }
      }
    );
  }

  private setStatus(status: MfDataManagerExportDownloaderStatusEnum): void {
    const pseudonymizationPasswordField = this.form.get('pseudonymizationPassword');

    if (pseudonymizationPasswordField) {
      switch (status) {
        case MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_ACTION:
          pseudonymizationPasswordField.setValue('');
          pseudonymizationPasswordField.setErrors(null);
          pseudonymizationPasswordField.markAsPristine();
          pseudonymizationPasswordField.markAsUntouched();
          pseudonymizationPasswordField.enable();
          break;

        case MfDataManagerExportDownloaderStatusEnum.ERROR:
          pseudonymizationPasswordField.enable();
          break;

        case MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_DOWNLOAD_ACTION:
        case MfDataManagerExportDownloaderStatusEnum.DOWNLOAD_ERROR:
        case MfDataManagerExportDownloaderStatusEnum.EMPTY:
          pseudonymizationPasswordField.disable();
          break;
      }
    }

    this.updateCardByStatus(status);
    this.status$.next(status);
  }

  private updateCardByStatus(status: MfDataManagerExportDownloaderStatusEnum): void {
    switch (status) {
      case MfDataManagerExportDownloaderStatusEnum.DECRYPT:
        this.icon = 'decrypt';
        this.iconAnimated = true;
        this.iconClass = undefined;
        break;

      case MfDataManagerExportDownloaderStatusEnum.GENERATE:
        this.icon = 'generate_file';
        this.iconAnimated = true;
        this.iconClass = undefined;
        break;

      case MfDataManagerExportDownloaderStatusEnum.COMPRESS:
        this.icon = 'compress';
        this.iconAnimated = true;
        this.iconClass = undefined;
        break;

      case MfDataManagerExportDownloaderStatusEnum.ERROR:
        this.icon = 'error';
        this.iconAnimated = false;
        this.iconClass = 'has-error';
        break;

      case MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_DOWNLOAD_ACTION:
      case MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_ACTION:
      default:
        this.icon = 'file';
        this.iconAnimated = false;
        this.iconClass = undefined;
        break;
    }
  }
}
