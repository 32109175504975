import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({ template: '' })
export abstract class MfFormAbstractFieldComponent<C = AbstractControl, G = FormGroup>
  implements OnInit
{
  @Input() group?: G;
  @Input() control!: C;
  @Input() name: string;

  public id: string;

  protected constructor() {
    this.id = crypto.randomUUID();
    this.name = '';
  }

  ngOnInit(): void {
    if (!this.control && this.group instanceof FormGroup) {
      this.control = this.group?.get(this.name)! as C;
    }

    if (!this.control) {
      throw Error(
        `Control (id: ${this.id}, name: ${this.name || 'undefined'}) could not resolve a control`
      );
    }
  }
}
