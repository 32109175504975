import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material/material.module';
import { Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'mf-table-search',
  templateUrl: './search.component.html',
  standalone: true,
  imports: [MaterialModule, FormsModule],
})
export class MfTableSearchComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() searchTerm: string = '';

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() debouncedSearch: EventEmitter<string> = new EventEmitter<string>();

  private destroyRef = inject(DestroyRef);
  private debouncer = new Subject<string>();

  ngOnInit(): void {
    this.debouncer
      .asObservable()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        debounce(() => timer(500))
      )
      .subscribe((value) => {
        this.debouncedSearch.emit(value);
      });
  }

  onSearch(value: string): void {
    this.search.emit(value);
    this.debouncer.next(value);
  }
}
