export type MfBankCustomizationValueType = Record<string, any> | string;
export type MfBankCustomizationType = Record<string, MfBankCustomizationValueType>;

export enum BankCustomizationKeys {
  FEATURES = 'features',
  SERVICE_PROVIDERS = 'serviceProviders',
}

export enum BankFeatures {
  CAMPAIGN_MEDIUM_EMAIL_V2 = 'campaign_medium_email_v2',
  CAMPAIGN_MEDIUM_LETTER_V2 = 'campaign_medium_letter_v2',
  CAMPAIGN_MEDIUM_LETTER_DELIVERY_METHOD = 'campaign_medium_letter_delivery_method',
  CAMPAIGN_ANALYSIS_CONSULTANT_LEAD_DETAILS = 'campaign_analysis_consultant_lead_details',
  CAMPAIGN_ANALYSIS_BRANCH_OFFICE_LEAD_DETAILS = 'campaign_analysis_branch_office_lead_details',
  CONSENT_ACTION = 'consent-action',
  CONSENT_ACTION_COLLECT_MISSING_EMAILS = 'collect_missing_emails_after_consent',
  CONSENT_ACTION_COLLECT_MISSING_EPOST = 'collect_missing_epost_after_consent',
  CONSENT_ACTION_PRODUCT_COMPONENTS = 'consent_action_product_components',
  CONSENT_ACTION_MORE_TOPICS = 'consent_action_more_topics',
  CONSENT_ACTION_VERIMI_COLLECT_QES = 'consent_action_verimi_collect_qes',
  CONSENT_ACTION_VOUCHERS = 'consent_action_vouchers',
  CONSENT_ACTION_YES_2FA_IDENTIFICATION = 'consent_action_yes_2fa_identification',
  CONSENT_ACTION_YES_IDENTIFICATION = 'consent_action_yes_identification',
  CONSENT_CONFIRM_USE_OF_DATA = 'consent_confirm_use_of_data',
  CONSULTANT_REQUIRES_PICTURE = 'consultant_requires_picture',
  EMAIL_APPOINTMENT_REMINDER = 'email_appointment_reminder',
  GDPR_RECITALS = 'gdpr_recitals',
  LETTER_PDF_ATTACHMENTS = 'letter_pdf_attachments',
  LETTER_SHIPPING_PROVIDER_SELECTION = 'letter_shipping_provider_selection',
  MARKETPLACE_BUYER = 'marketplace_buyer',
  MARKETPLACE_DUTY_CAMPAIGNS = 'marketplace_duty_campaigns',
  MARKETPLACE_LANDINGPAGES = 'marketplace_landingpages',
  MARKETPLACE_SELLER = 'marketplace_seller',
  MARKETPLACE_SUBSCRIPTION_PROVIDER = 'marketplace_subscription_provider',
  MARKETPLACE_SELECT_DEPARTMENT_X_PROMOTIONS = 'marketplace_select_department_x_promotions',
  NEWSLETTER = 'newsletter',
  PRODUCTS = 'products',
  RPA = 'rpa',
  SMS = 'sms',
  TARGET_GROUP_BANKINGONE_AI = 'target_group_bankingone_ai',
  TARGET_GROUP_MITTELSTAND_AI = 'target_group_mittelstand_ai',
  CONSULTANT_WIDGET = 'consultant_widget',
}

export type BankCustomizationServiceProviders = {
  [BankCustomizationServiceProvidersEnum.LETTER_SHIPPING_PROVIDER]?: BankLetterShippingProviders;
  [BankCustomizationServiceProvidersEnum.TARGET_GROUP_ANALYSIS_PROVIDER]?: BankTargetGroupAnalysisProviders;
};

export enum BankCustomizationServiceProvidersEnum {
  LETTER_SHIPPING_PROVIDER = 'letterShippingProvider',
  TARGET_GROUP_ANALYSIS_PROVIDER = 'targetGroupAnalysisProvider',
}

export enum BankLetterShippingProviders {
  SCHWAEBISCH_HALL = 'schwaebisch_hall',
  PIN_AG = 'pin_ag',
}

export enum BankTargetGroupAnalysisProviders {
  MITTELSTAND_AI = 'mittelstand_ai',
}

export const letterShippingProviders: BankLetterShippingProviders[] = [
  BankLetterShippingProviders.SCHWAEBISCH_HALL,
  BankLetterShippingProviders.PIN_AG,
];

export const targetGroupAnalysisProviders: BankTargetGroupAnalysisProviders[] = [
  BankTargetGroupAnalysisProviders.MITTELSTAND_AI,
];

export interface MfBankAvailableFeature {
  name: string;
  label: string;
  value: boolean;
  disabled: boolean;
}
