import { Maybe, OptionalRecord } from '@shared/types/util.types';

export type MfArrayUtilPartitionResult<T> = [pass: T[], fail: T[]];

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T; // from lodash

export namespace ArrayUtil {
  export const lastItem = <T>(array: Maybe<T[]>): T | undefined => {
    if (!isNonEmptyArray(array)) {
      return undefined;
    }

    return array[array.length - 1];
  };

  /**
   * Partition your array with a custom filter
   *
   *  @param array - The array to be partitioned
   *  @param filterFn - The filter condition to partition the array
   *  @returns A 2D Array, the first column includes passing values for the given filter,
   *  the second column the failed values
   */
  export const partition = <T>(
    array: T[],
    filterFn: (e: T, idx: number, arr: T[]) => boolean
  ): MfArrayUtilPartitionResult<T> => {
    const pass: T[] = [];
    const fail: T[] = [];

    try {
      array.forEach((e: T, idx: number, arr: T[]) => {
        (filterFn(e, idx, arr) ? pass : fail).push(e);
      });
    } catch (e) {
      throw Error(String(e));
    }

    return [pass, fail];
  };

  /**
   * Group the entries of your array with a custom groupBy function
   *
   * @param array - The array to be grouped
   * @param getKey - Function that returns the key for the group
   * @returns A object with the grouped entries
   */
  export const groupBy = <T, K extends string | number>(
    array: T[],
    getKey: (i: T) => Maybe<K>
  ): OptionalRecord<K, T[]> =>
    array.reduce<OptionalRecord<K, T[]>>((previous, currentItem) => {
      const group = getKey(currentItem);

      if (group) {
        if (!previous[group]) {
          previous[group] = [];
        }

        previous[group]!.push(currentItem);
      }

      return previous;
    }, {});

  /**
   * Converts a scalar or array value to an array. If the scalar value is undefined, an empty array is returned
   */
  export const toArray = <T>(scalarOrArray: T | T[]): T[] => {
    if (Array.isArray(scalarOrArray)) {
      return scalarOrArray;
    }

    return scalarOrArray !== undefined ? [scalarOrArray] : [];
  };

  /**
   * Check if a value is an array that contains at least one item
   */
  export const isNonEmptyArray = <T>(value: any | T[]): value is T[] =>
    Array.isArray(value) && !!value.length;

  /**
   * Checks if array2 begins with items of array1
   */
  export const beginsWithSameItems = <T>(array1: T[], array2: T[]): boolean =>
    array1.every((item, index) => item === array2[index]);

  /**
   * Checks if searchArray has items of baseArray
   */
  export const intersection = <T>(searchArray: T[], baseArray: T[]): boolean =>
    baseArray.some((item) => searchArray.includes(item));

  /**
   * Joins the array with a special separator between the last element and the element before
   */
  export const joinWithDifferentLastSeparator = <T>(
    array: T[],
    separator: string,
    lastSeparator: string
  ): string =>
    [array.slice(0, -1).join(separator), array[array.length - 1]]
      .filter((v) => Boolean(v))
      .join(lastSeparator);

  /**
   * Generates all numbers between the start and end value with the specified step-size and
   * returns them as an array. The end-value will not be included.
   * */
  export const range = (start: number, end: number, step: number = 1): number[] => {
    const items: number[] = [];

    for (let i = start; i < end; i += step) {
      items.push(i);
    }

    return items;
  };

  /**
   * This function is intended to be used as an Array.filter() callback for cases where falsy values
   * need to be filtered out. This ensures that the resulting array will have the proper type.
   *
   * Example usage:
   * const array = [null, 'some value', null, 123]; // -> (null | string)[]
   * const filteredArray = array.filter(ArrayUtil.truthyFilter); // -> string[]
   * */
  export const truthyFilter = <T>(value: T): value is Truthy<T> => !!value;

  export const move = <T>(items: T[], previousIndex: number, targetIndex: number): T[] => {
    const result: T[] = [...items];

    if (result.length && previousIndex >= 0 && targetIndex >= 0) {
      const [element] = result.splice(previousIndex, 1);

      if (element !== undefined) {
        if (targetIndex <= 0) {
          result.unshift(element);
        } else if (targetIndex > result.length - 1) {
          result.push(element);
        } else {
          result.splice(targetIndex, 0, element);
        }
      }
    }

    return result;
  };

  /**
   * Determines where 2 arrays contain the same items
   * */
  export const containSameItems = <T>(array1: T[], array2: T[]) =>
    array1.every((item) => array2.includes(item)) && array2.every((item) => array1.includes(item));

  /**
   * Collects elements of the array into groups of specified size. Note: The last group may contain less items
   */
  export const toGroupsOfSize = <T>(array: T[], size: number): T[][] => {
    if (size < 1 || !Number.isInteger(size)) {
      throw Error('toGroupsOfSize requires size to be an integer larger or equal 1');
    }

    const result: T[][] = [];
    let currentGroup: T[] = [];
    for (let i = 0; i < array.length; i++) {
      currentGroup.push(array[i]);

      if (currentGroup.length === size) {
        result.push(currentGroup);
        currentGroup = [];
      }
    }

    if (currentGroup.length) {
      result.push(currentGroup);
    }

    return result;
  };

  export const toScalar = <T>(arrayOrScalar: T | T[]): T =>
    Array.isArray(arrayOrScalar) ? arrayOrScalar[0] : arrayOrScalar;

  export const repeat = <T>(value: T, count: number): T[] => {
    const result: T[] = Array.from({ length: count });

    for (let i = 0; i < count; i++) {
      result[i] = value;
    }

    return result;
  };

  export const getCombinations = <T>(arr: T[]): T[][] => {
    const result: T[][] = [];
    const n = arr.length;

    const generate = (index: number, subset: T[]) => {
      if (index === n) {
        if (subset.length > 0) {
          result.push([...subset]);
        }

        return;
      }

      // Element hinzufügen
      subset.push(arr[index]);
      generate(index + 1, subset);

      // Element entfernen
      subset.pop();
      generate(index + 1, subset);
    };

    generate(0, []);

    return result;
  };

  export const includesAll = <T>(arr: T[], needles: T[]): boolean =>
    needles.every((needle) => arr.includes(needle));

  export const sortByOrder = <T>(arr: T[], order: T[]): T[] => {
    const orderMap = new Map(order.map((item, index) => [item, index]));

    return [...arr].sort((a, b) => (orderMap.get(a) ?? Infinity) - (orderMap.get(b) ?? Infinity));
  };

  export const sortByLength = <T>(arr: T[][]): T[][] =>
    [...arr].sort((a, b) => a.length - b.length);
}
