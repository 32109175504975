import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Event, NavigationEnd, Router, RouterLink } from '@angular/router';
import { MfLayoutSidenavContentComponent } from '@app/layout/sidenav/content/content.component';
import { MfLayoutSidenavTabsComponent } from '@app/layout/sidenav/tabs/tabs.component';
import { MfLayoutSidenavUserActionsComponent } from '@app/layout/sidenav/user-actions/user-actions.component';
import { MfUserEventType } from '@app/shared/authentication/user/user-event.model';
import { MfUserEventService } from '@app/shared/authentication/user/user-event.service';
import { MfUserService } from '@app/shared/authentication/user/user.service';
import { MfNavigationService } from '@app/shared/navigation/navigation.service';
import { MfUserUtil } from '@app/shared/util/user.util';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { MfUserRolesEnum } from '@shared/authentication/types/user_roles.enum';
import { MfDataUserInterface } from '@shared/data/data.interface';
import { Maybe } from '@shared/types/util.types';
import { Observable } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'mf-layout-sidenav',
  templateUrl: './sidenav.component.html',
  styles: [
    `
      :host {
        @apply flex h-full flex-col divide-y divide-monochrome-500;
      }
    `,
  ],
  standalone: true,
  imports: [
    TranslocoDirective,
    RouterLink,
    MfLayoutSidenavContentComponent,
    MfLayoutSidenavUserActionsComponent,
    MfLayoutSidenavTabsComponent,
  ],
})
export class MfLayoutSidenavComponent implements OnInit {
  public entityName: string = '';
  public userName: string = '';

  private destroyRef = inject(DestroyRef);

  constructor(
    private userService: MfUserService,
    private activatedRoute: ActivatedRoute,
    private userEventService: MfUserEventService,
    private translocoService: TranslocoService,
    private router: Router,
    private titleService: Title,
    private navigationService: MfNavigationService
  ) {}

  ngOnInit(): void {
    this.setPageTitle();
    this.initUserDataUpdater();
  }

  get hasTabs() {
    if (
      !this.userService.hasRole(MfUserRolesEnum.MARKETPLACE_SELLER) &&
      !this.userService.hasRole(MfUserRolesEnum.MARKETPLACE_BUYER)
    ) {
      return false;
    }

    if (
      !this.userService.hasRole(MfUserRolesEnum.BANK_ADMIN) &&
      !this.userService.hasRole(MfUserRolesEnum.CAMPAIGN_MANAGER) &&
      !this.userService.hasRole(MfUserRolesEnum.DATA_MANAGER) &&
      !this.userService.hasRole(MfUserRolesEnum.REVISION) &&
      !this.userService.hasRole(MfUserRolesEnum.CONTRACT_MANAGER) &&
      !this.userService.hasRole(MfUserRolesEnum.BACK_OFFICE) &&
      !this.userService.hasRole(MfUserRolesEnum.PARTNER_BANK_EMPLOYEE) &&
      !this.userService.hasRole(MfUserRolesEnum.PARTNER_ADMIN) &&
      !this.userService.hasRole(MfUserRolesEnum.PARTNER_MARKETING_MANAGER) &&
      !this.userService.hasRole(MfUserRolesEnum.PARTNER_DATA_MANAGER) &&
      !this.userService.hasRole(MfUserRolesEnum.ADMIN_BANK_MANAGEMENT) &&
      !this.userService.hasRole(MfUserRolesEnum.ADMIN_SUPER_USER_MANAGEMENT) &&
      !this.userService.hasRole(MfUserRolesEnum.ADMIN_USER_MANAGEMENT) &&
      !this.userService.hasRole(MfUserRolesEnum.ADMIN_EMAIL_TEMPLATES) &&
      !this.userService.hasRole(MfUserRolesEnum.ADMIN_DOCUMENT_MANAGEMENT) &&
      !this.userService.hasRole(MfUserRolesEnum.ADMIN_MAILJET) &&
      !this.userService.hasRole(MfUserRolesEnum.ADMIN_DEPARTMENT_X) &&
      !this.userService.hasRole(MfUserRolesEnum.ADMIN_MEDIA_LIBRARY) &&
      !this.userService.hasRole(MfUserRolesEnum.ADMIN_IDA) &&
      !this.userService.hasRole(MfUserRolesEnum.ADMIN_VIDEO_MANAGEMENT) &&
      !this.userService.hasRole(MfUserRolesEnum.ADMIN_CONTRACT_MANAGEMENT) &&
      !this.userService.hasRole(MfUserRolesEnum.ADMIN_BACK_OFFICE)
    ) {
      return false;
    }

    return true;
  }

  private initUserDataUpdater(): void {
    this.userEventService
      .onEvent()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((event) => {
          switch (event.type) {
            case MfUserEventType.LOGIN:
            case MfUserEventType.UPDATED:
              return event.data;

            case MfUserEventType.LOGOUT:
              return null;
          }
        }),
        startWith(this.userService.getUser())
      )
      .subscribe({
        next: (user) => {
          this.setUserInfo(user);
        },
      });
  }

  private setUserInfo(user: Maybe<MfDataUserInterface>): void {
    if (!user) {
      this.userName = '';
      this.entityName = '';

      return;
    }

    this.userName = MfUserUtil.formatFullName(user);

    if (user._embedded?.bank) {
      this.entityName = user._embedded.bank.name;
    } else {
      this.translocoService
        .selectTranslate('SHARED.INDIWE')
        .pipe(take(1))
        .subscribe((translation: string) => {
          this.entityName = translation;
        });
    }
  }

  public redirectUrl(): string[] {
    return this.userService.isLoggedIn()
      ? [`/${this.navigationService.suggestDefaultRouteCurrentArea()}`]
      : ['/'];
  }

  get logoTitle(): string {
    return this.navigationService.logo.imageAlt;
  }

  get logo(): string {
    return this.navigationService.logo.imageUrl;
  }

  private setPageTitle(): void {
    this.getPageTitleFromRouter().subscribe((activatedRoute: ActivatedRoute) => {
      const data: Data = activatedRoute.snapshot.data;
      let pageTitle: string;

      if (typeof data['pageTitle'] === 'function') {
        pageTitle = data['pageTitle'](activatedRoute.snapshot);
      } else {
        pageTitle = data['pageTitle'];
      }

      pageTitle = pageTitle || 'SHARED.MARKETING_FACTORY.TITLE';

      this.translocoService.selectTranslate(pageTitle).subscribe((title: string) => {
        this.titleService.setTitle(title);
      });
    });
  }

  private getPageTitleFromRouter(): Observable<ActivatedRoute> {
    return this.router.events.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter((event: Event) => event instanceof NavigationEnd),
      startWith(null),
      map(() => this.activatedRoute),
      map((route: ActivatedRoute) => {
        let currentRoute = route;

        while (currentRoute.firstChild) {
          currentRoute = currentRoute.firstChild;
        }

        return currentRoute;
      })
    );
  }
}
