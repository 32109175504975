import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MaterialModule } from '@app/material/material.module';
import { MfNavigationService } from '@app/shared/navigation/navigation.service';
import { MfSidenavOptimizedTopLevelItem } from '@app/shared/navigation/navigation.types';
import { fadeToggle } from '@app/shared/util/animations/fade-toggle';
import { environment } from '@env/environment';
import { EnvironmentTypeEnum } from '@env/environment-type.enum';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'mf-layout-sidenav-content',
  templateUrl: './content.component.html',
  styles: [
    `
      :host {
        @apply flex min-h-0 flex-1 flex-col space-y-4 overflow-y-auto py-4;
      }
    `,
  ],
  animations: [fadeToggle],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, RouterLink, RouterLinkActive, MaterialModule],
})
export class MfLayoutSidenavContentComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  public sidenavItems: MfSidenavOptimizedTopLevelItem[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private navService: MfNavigationService
  ) {}

  get showNavigation(): boolean {
    return !localStorage.getItem('passwordChangeRequired');
  }

  get showToolboxItem(): boolean {
    return !!this.sidenavItems.length && ![EnvironmentTypeEnum.PROD].includes(environment.type);
  }

  ngOnInit(): void {
    this.navService.sidenavItems.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (items) => {
        this.sidenavItems = items;
        this.cd.detectChanges();
      },
    });
  }
}
