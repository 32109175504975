<ng-container *transloco="let t; scope: 'dataTransfer'">
  <form [formGroup]="passwordForm" (ngSubmit)="onFormSubmit()" mfFocusFirstInvalidField novalidate>
    <div class="form-container-lg" [ngClass]="{ medium: isUsedInWidget }">
      @if (showDecryptPassword) {
        <mf-form-input
          [group]="passwordForm"
          [label]="t('SHARED.FORMS.LABELS.ENCRYPTION_PASSWORD')"
          name="zipPassword"
          autoFocus
          passwordHasToggleButton
          patternName="password"
          type="password"
        />
      }

      @if (showPseudonymizationPassword) {
        <mf-form-input
          [autoFocus]="!showDecryptPassword"
          [group]="passwordForm"
          [label]="t('SHARED.FORMS.LABELS.PSEUDONYMIZATION_PASSWORD')"
          name="password"
          passwordHasToggleButton
          patternName="password"
          type="password"
        />
      }
    </div>

    <div class="-mt-1">
      <div class="flex gap-4" [ngClass]="isUsedInWidget ? 'flex-col' : 'flex-row'">
        <mf-primary-button
          [disabled]="passwordForm.pending"
          [label]="primaryActionLabel"
          autoStretch
          type="submit"
        />

        <mf-secondary-button
          [label]="t('dataTransfer.IMPORT.UPLOAD_CARD.ACTIONS.CANCEL_IMPORT')"
          (clicked)="cancelPatch.emit()"
          autoStretch
        />
      </div>
    </div>
  </form>
</ng-container>
