import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  DestroyRef,
  Directive,
  DoCheck,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnInit,
  Optional,
  Renderer2,
  Self,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroupDirective, NgControl, NgForm, UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject } from 'rxjs';

// https://gist.github.com/jean-merelis/44a3ed842c24e99d38cd2f1e9249c473#file-ng-select-directive-ts

export class NgSelectErrorStateMatcher {
  constructor(private ngSelect: NgSelectFormFieldControlDirective) {}

  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    if (!control) {
      return this.ngSelect.required && this.ngSelect.empty;
    } else {
      return !!(control?.invalid && (control?.touched || form?.submitted));
    }
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngSelectMat]',
  providers: [{ provide: MatFormFieldControl, useExisting: NgSelectFormFieldControlDirective }],
  standalone: true,
})
export class NgSelectFormFieldControlDirective
  implements MatFormFieldControl<any>, OnInit, DoCheck
{
  private destroyRef = inject(DestroyRef);

  static nextId: number = 0;
  @HostBinding() @Input() id: string = `ng-select-${NgSelectFormFieldControlDirective.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy: string = '';

  errorState: boolean = false;
  @Input() errorStateMatcher!: ErrorStateMatcher;
  private xDefaultErrorStateMatcher: ErrorStateMatcher = new NgSelectErrorStateMatcher(this);

  stateChanges: Subject<void> = new Subject<void>();
  focused: boolean = false;

  get empty(): boolean {
    return this.value === undefined || this.value === null;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this.xPlaceholder;
  }

  set placeholder(value: string) {
    this.xPlaceholder = value;
    this.stateChanges.next();
  }

  private xPlaceholder: string = '';

  @Input()
  get required(): boolean {
    return this.xRequired;
  }

  set required(value: boolean) {
    this.xRequired = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private xRequired: boolean = false;

  @Input()
  get disabled(): boolean {
    return this.xDisabled;
  }

  set disabled(value: boolean) {
    this.xDisabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private xDisabled: boolean = false;

  @Input()
  get value(): any {
    return this.xValue;
  }

  set value(v: any) {
    if (v) {
      this.addClass('has-value');
    } else {
      this.removeClass('has-value');
    }

    this.xValue = v;
    this.stateChanges.next();
  }

  private xValue: any;

  constructor(
    private host: NgSelectComponent,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private xParentForm: NgForm,
    @Optional() private xParentFormGroup: FormGroupDirective
  ) {
    host.focusEvent
      .asObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.focused = true;
        this.stateChanges.next();
      });
    host.blurEvent
      .asObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.focused = false;
        this.stateChanges.next();
      });
  }

  ngOnInit(): void {
    if (this.ngControl) {
      this.value = this.ngControl.value;
      this.disabled = !!this.ngControl.disabled;
      this.ngControl.statusChanges
        ?.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((s: any) => {
          const disabled: boolean = s === 'DISABLED';
          if (disabled !== this.disabled) {
            this.disabled = disabled;
          }
        });
      this.ngControl.valueChanges?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((v: any) => {
        this.value = v;
        this.host.detectChanges();
        this.stateChanges.next();
      });
    } else {
      this.host.changeEvent
        .asObservable()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((v: any) => {
          this.value = v;
          this.host.detectChanges();
          this.stateChanges.next();
        });
    }
  }

  ngDoCheck(): void {
    // We need to re-evaluate this on every change detection cycle, because there are some
    // error triggers that we can't subscribe to (e.g. parent form submissions). This means
    // that whatever logic is in here has to be super lean or we risk destroying the performance.
    this.updateErrorState();
  }

  updateErrorState(): void {
    const oldState: boolean = this.errorState;
    const parent: NgForm | FormGroupDirective = this.xParentFormGroup || this.xParentForm;
    const matcher: ErrorStateMatcher = this.errorStateMatcher || this.xDefaultErrorStateMatcher;
    const control = this.ngControl ? (this.ngControl.control as UntypedFormControl) : null;
    const newState: boolean = matcher.isErrorState(control, parent);

    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    const target: HTMLElement = event.target as HTMLElement;
    if (target.classList.contains('mat-form-field-infix')) {
      this.host.focus();
      this.host.open();
    }
  }

  addClass(className: string): void {
    this.renderer.addClass(this.elementRef.nativeElement, className);
  }

  removeClass(className: string): void {
    this.renderer.removeClass(this.elementRef.nativeElement, className);
  }
}
