<ng-container *transloco="let t; scope: 'organization'">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" mfFocusFirstInvalidField novalidate>
    <h2 class="mat-headline" mat-dialog-title>{{ data.title }}</h2>
    <div class="overflow-visible" mat-dialog-content>
      <mf-form-select
        class="mt-2"
        [clearable]="data.optional"
        [group]="form"
        [items]="items"
        [label]="data.label"
        [showOptionalHint]="data.optional"
        name="item"
      />
    </div>
    <div class="styled" mat-dialog-actions>
      <mf-tertiary-button class="mr-2" [label]="t('SHARED.ACTION.CANCEL')" (clicked)="close()" />
      <mf-primary-button [label]="t('SHARED.ACTION.SAVE_CHANGES')" hasInitialFocus type="submit" />
    </div>
  </form>
</ng-container>
